import useMediaQuery from "../../utils/useMediaQuery";
import ImageContainer from "../ImageContainer";
import Slider from "react-slick";

function ImageGallery({ images }) {
  const { isXs } = useMediaQuery();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {!isXs ? (
        <div
          style={{
            overflow: "auto",
            width: "50%",
          }}
        >
          {images.map((image) => (
            <ImageContainer {...image} />
          ))}
        </div>
      ) : (
        <div>
          <Slider {...settings}>
            {images.map((image) => (
              <ImageContainer {...image} height={"430px"} />
            ))}
          </Slider>
        </div>
      )}
    </>
  );
}

export default ImageGallery;
