import useMediaQuery from "../../utils/useMediaQuery";
import ImageContainer from "../ImageContainer";
import IconRow from "../IconRow";

function HeroImage({ width }) {
  const { isXs, isSm } = useMediaQuery();
  return (
    <ImageContainer
      width={width}
      height={isXs ? "95%" : "100%"}
      src={"/images/merry.webp"}
      alt={"merry"}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          bottom: isXs ? 50 : 100,
          left: isXs ? 160 : 20,
          padding: "0px 20px",
        }}
      >
        <IconRow />
        <h1
          style={{
            color: "white",
            margin: 0,
            fontSize: isSm ? "65px" : "90px",
          }}
          className="bolder"
        >
          merry tran
        </h1>
        <a href="/about">
          <h2 className="bolder alt-text m0 link">about me</h2>
        </a>
      </div>
    </ImageContainer>
  );
}

export default HeroImage;
