import "../styles/About.scss";
import HeroImage from "../components/HeroImage";
import useMediaQuery from "../utils/useMediaQuery";
import ContainerWithTitle from "../components/Container/ContainerWithTitle";

function About() {
  const { isXs, isMd } = useMediaQuery();
  return (
    <section
      style={{
        height: "100vh",
        width: "100vw",
        display: isXs ? "block" : "flex",
      }}
    >
      <HeroImage width={isXs ? "100vw" : "45vw"} />
      <ContainerWithTitle title={"about me"} width={isXs ? "100vw" : "50vw"}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <p>
            My name is Merry Tran & I currently reside in Palos Verdes,
            California.
          </p>
          <p>
            I am an E-Learning Developer and Instructional Designer and have
            been in the industry for almost 5 years. I obtained my Master’s of
            Science in State University, Fullerton in 2018. In 2020, I started
            work as an e-learning developer and instructional designer at
            Evosus, an ERP software company located in Vancouver, WA. There, I
            created both in-house onboarding courses and industry-specific
            courses for Evosus’s clientele. In 2022, I began employment at
            American Honda Motors Headquarters located in Torrance, CA. Under
            Parts & Training, I was responsible for the standardization of Honda
            and Acura’s technical and fix ops courses that are viewed globally
            by Honda and Acura technicians and service advisors. Alongside my
            work as an e-learning developer, I also am a freelance photographer,
            video editor, and graphic designer.
          </p>
          <p>
            Currently, I am navigating the world of mobile development, training
            for a half marathon, and making stationary for the fun of it.
          </p>
        </div>
      </ContainerWithTitle>
    </section>
  );
}

export default About;
