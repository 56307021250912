import useMediaQuery from "../utils/useMediaQuery";
import ContainerWithTitle from "../components/Container/ContainerWithTitle";
import ImageGallery from "../components/ImageGallery";

function Graphics() {
  const { isXs } = useMediaQuery();
  const images = [
    {
      height: "70%",
      width: "100%",
      src: "/images/graphics/ccmenu.svg",
      alt: "chai chi menu",
    },
    {
      height: "100%",
      width: "100%",
      src: "/images/graphics/lox.webp",
      alt: "live laugh lox",
    },
  ];
  if (!isXs) {
    images.unshift({
      height: "30%",
      width: "100%",
      src: "/images/graphics/cclogo.svg",
      alt: "chai chi logo",
    });
  }

  return (
    <section
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        display: isXs ? "block" : "flex",
      }}
    >
      <ContainerWithTitle title={"graphics"}>
        <p style={{ margin: "2rem 0" }}>
          If you are interested in graphic design work or art commissions,
          please inquire with the contact link below. If you are interested in
          supporting my art, please visit my etsy store coming very soon.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "1",
          }}
        >
          <h2 className="bolder m0">
            <a href="mailto:merryutran@gmail.com" className="secondary">
              inquiries
            </a>
          </h2>
          <h2 className="bolder m0" style={{ color: "gray" }}>
            {/* <a href="#" className="secondary"> */}
            etsy store (coming soon)
            {/* </a> */}
          </h2>
        </div>
      </ContainerWithTitle>
      <ImageGallery images={images} />
    </section>
  );
}

export default Graphics;
