import useMediaQuery from "../utils/useMediaQuery";
import ContainerWithTitle from "../components/Container/ContainerWithTitle";
import ImageGallery from "../components/ImageGallery";

function Photography() {
  const { isXs } = useMediaQuery();
  const formUrl =
    "https://docs.google.com/forms/d/1g5MwE2b1tEuVb2awlaKAVJZylKSd7OLlXQRF1PUCk2U/edit ";
  const workUrl = "https://www.instagram.com/merry.fotos";

  const images = [
    {
      height: "100%",
      width: "100%",
      src: "/images/photography/1.webp",
      alt: "jess-khiet",
    },
    {
      height: "100%",
      width: "100%",
      src: "/images/photography/2.webp",
      alt: "jk-church",
    },
    {
      height: "100%",
      width: "100%",
      src: "/images/photography/3.webp",
      alt: "train",
    },
    {
      height: "100%",
      width: "100%",
      src: "/images/photography/4.webp",
      alt: "baby",
    },
  ];

  return (
    <section
      style={{
        height: "100%",
        width: "100%",
        display: isXs ? "block" : "flex",
        overflow: "hidden",
      }}
    >
      <ContainerWithTitle title={"photography"}>
        <p style={{ margin: "1.5rem 0" }}>
          I am currently open to inquiries! For rates or questions, please
          contact me via email or fill out the inquiry form below. I will
          usually get back to you within 24 hours
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "1",
          }}
        >
          <h2 className="bolder m0">
            <a
              href={formUrl}
              className="secondary"
              rel="noreferrer noopener"
              target="_blank"
            >
              inquiries
            </a>
          </h2>
          <h2 className="bolder m0">
            <a
              href={workUrl}
              className="secondary"
              rel="noreferrer noopener"
              target="_blank"
            >
              view work
            </a>
          </h2>
        </div>
      </ContainerWithTitle>
      <ImageGallery images={images} />
    </section>
  );
}

export default Photography;
