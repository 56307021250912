import React from "react";

const ImageContainer = ({
  src,
  width,
  height,
  focalX = 50,
  focalY = 50,
  children,
  alt,
}) => {
  const containerStyle = {
    width: `${width}`,
    height: `${height}`,
    overflow: "hidden",
    position: "relative",
  };

  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-${focalX}%, -${focalY}%)`,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const contentWrapperStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  return (
    <div style={containerStyle}>
      <img src={src} alt={alt} style={imageStyle} />
      <div style={contentWrapperStyle}>{children}</div>
    </div>
  );
};

export default ImageContainer;
